.Home-module__nD80lq__home-page {
  flex-flow: column;
  padding: 20px;
  display: flex;
}

.Home-module__nD80lq__home-page__breadcrumbs {
  margin-bottom: 10px;
}

.Home-module__nD80lq__home-page__content-wrapper, .Home-module__nD80lq__home-page__news-and-blogs-wrapper {
  gap: 20px;
  display: flex;
}

.Home-module__nD80lq__home-page__left-column {
  width: 100%;
}

.Home-module__nD80lq__home-page__news-and-blogs-wrapper {
  padding-top: 20px;
}

.Home-module__nD80lq__home-page__match-teaser-wrapper {
  padding-bottom: 20px;
}

.Home-module__nD80lq__home-page__top-post {
  max-height: 425px;
}

.Home-module__nD80lq__home-page__news-container {
  flex-flow: column;
  gap: 20px;
  width: 300px;
  display: flex;
}

.Home-module__nD80lq__home-page__news-container__ads {
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
}

.Home-module__nD80lq__home-page__right-column {
  flex-flow: column;
  gap: 20px;
  width: 300px;
  min-width: 300px;
  display: flex;
}

.Home-module__nD80lq__home-page__right-column__ads--sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
}

.Home-module__nD80lq__home-page__blogs-container {
  width: 100%;
  max-width: 600px;
}

.Home-module__nD80lq__home-page__rb-widget {
  display: block;
}

.Home-module__nD80lq__home-page__sport-rb-widget {
  margin-bottom: 20px;
}

.Home-module__nD80lq__home-page__sport-title {
  color: var(--text-primary);
  margin-bottom: 20px;
}

.Home-module__nD80lq__home-page__revert {
  flex-direction: column-reverse;
  display: flex;
}

.Home-module__nD80lq__home-page--sticky {
  z-index: 2;
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
}

.Home-module__nD80lq__news-grid {
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  width: 100%;
  display: flex;
}

@media (max-width: 660px) {
  .Home-module__nD80lq__home-page {
    margin: 10px 0 0;
    padding: 0;
  }

  .Home-module__nD80lq__home-page__breadcrumbs, .Home-module__nD80lq__home-page__sport-title {
    margin-left: 10px;
  }

  .Home-module__nD80lq__home-page__news-and-blogs-wrapper {
    flex-wrap: wrap;
  }

  .Home-module__nD80lq__home-page__content-wrapper {
    flex-flow: column;
    width: 100%;
    padding: 10px;
  }

  .Home-module__nD80lq__home-page__top-post {
    height: 255px;
    margin: 0 -10px;
  }

  .Home-module__nD80lq__home-page__right-column {
    width: 100%;
  }

  .Home-module__nD80lq__home-page__right-column__ads, .Home-module__nD80lq__home-page__right-column__ads--sticky {
    display: none;
  }

  .Home-module__nD80lq__home-page__news-container, .Home-module__nD80lq__home-page__blogs-container {
    width: 100%;
  }

  .Home-module__nD80lq__home-page__match-teaser-wrapper {
    padding: 0 10px 10px;
  }

  .Home-module__nD80lq__home-page__rb-widget {
    display: none;
  }

  .Home-module__nD80lq__home-page__sport-rb-widget {
    margin: 0 10px 10px;
  }
}

